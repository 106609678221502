@import '../_variables.scss';
@import '../_mixins.scss';

.bp3-popover-target > div:focus {
    outline: none !important;
    box-shadow: none !important;
}

.bp3-tooltip .bp3-popover-content {
    font-size: 12px;
    border-radius: 0 !important;
}

span.bp3-popover-target {
    display: block !important;
}

.tnl-tooltip-portal {
    pointer-events: none !important;
}
