@import './_variables.scss';
@import './_mixins.scss';

.error-history-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $page-dark;

    // Error History
    .error-title {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: 20px 0;
        border-bottom: 1px solid $outline-grey;

        @include media-breakpoint-up(md) {
            padding-left: 20px;
            padding-right: 20px;
        }

        h2 {
            margin-bottom: 0;
        }

        .error-status-icon {
            flex-shrink: 0;
            margin-right: 10px;
        }

        .error-history-actions {
            margin-left: auto;
        }
    }

    .error-history {
        flex: 1 100%;
        width: 100%;
        padding: 0 20px;
    }

    // Tile Error History
    .tile-title {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 20px;
        padding: 20px 0;
        border-bottom: 1px solid $outline-grey;

        @include media-breakpoint-up(md) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .tile-info {
            display: flex;
            flex-direction: column;
            overflow-x: auto;

            .tile-details {
                display: inline-flex;

                .details {
                    display: inline-flex;
                    white-space: nowrap;
                    margin-top: 10px;
                    margin-right: 20px;

                    h4,
                    h5 {
                        margin-bottom: 0;
                    }

                    h4 {
                        margin-right: 8px;
                    }
                }
            }
        }

        .section-tile {
            margin-right: 10px;
        }

        .tile-history-actions {
            margin-left: auto;
        }
    }

    .tile-history {
        display: flex;
        flex-direction: column;
        flex: 1 100%;
        padding: 0 20px;

        .error-log-wrapper {
            flex: 1 100%;
            width: 100%;
        }
    }

    .tile-history-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
