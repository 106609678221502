@import './_variables.scss';
@import './_mixins.scss';

.settings-title {
    padding-top: 10px;
    background-color: $page-dark;

    @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.settings-tabs {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include media-breakpoint-down(md) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.settings-container {
    min-height: calc(100vh - 71px); // Fallback
    min-height: calc(var(--vh, 1vh) * 100 - 71px);
    padding-top: 10px;
    padding-bottom: 60px; // Allow space for help widget and offline banner
    background-color: $page-dark;
}

.details-tab {
    h4,
    h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    }

    .details-title {
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            margin-bottom: 0;
        }

        .custom-button-edit {
            margin-left: 10px;
        }
    }
}

.details-form {
    padding: 20px;

    input {
        margin-bottom: 10px;
    }
}

.preferences-tab {
    .language-selector,
    .custom-select-wrapper {
        width: 170px;
    }

    .time-zone-form {
        h6 {
            margin-bottom: 0;
            margin-right: 10px;
        }
    }
}

.settings-toggles {
    .type-row {
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid $outline-grey;

        h4 {
            margin-bottom: 0;
        }

        .checkbox-help {
            width: 25px;
        }
    }

    .checkbox-col {
        display: flex;
        justify-content: center;
    }
}

.organisation-details {
    margin-top: 20px;
}

.qr-container {
    display: inline-block;
    padding: 10px;
    border: 1px solid $outline-grey;
    border-radius: 0.25rem;

    .qr-loading {
        height: 128px;
        width: 128px;
    }
}

.label-grid {
    display: grid;
    grid-gap: 10px;
    max-width: 500px;
}

.label-row {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    border: 1px solid $input-border;
    padding: 10px;
    border-radius: 5px;

    h4 {
        margin-bottom: 0;
    }
}
