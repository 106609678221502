@import './_variables.scss';
@import './_mixins.scss';

.auth-container {
    display: flex;
    height: 100%;
    min-height: 100vh; // Fallback
    min-height: calc(var(--vh, 1vh) * 100);
}

.auth-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh; // Fallback
    padding: 20px;

    @include media-breakpoint-down(md) {
        min-height: 100vh; // Fallback
        min-height: calc(var(--vh, 1vh) * 100);
    }
}

.auth-sign-in {
    display: flex;
    align-content: center;
    width: 100%;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    .auth-mobile-header {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: auto;

        .auth-mobile-logo {
            height: 35px;
        }
    }

    .auth-leftside-bar {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 450px;
        background-color: $header-dark;

        .auth-leftside-bar-top {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;

            img {
                width: 150px;
            }

            .auth-leftside-bar-text {
                margin-top: 54px;
                max-width: 330px;
                color: $text-title;
                font-family: $font-family-light;
                font-size: 22px;
            }
        }

        .auth-leftside-bar-bottom {
            margin-bottom: 16px;

            img {
                width: 122px;
            }

            .auth-leftside-bar-smalltext {
                margin-top: 20px;
                font-size: 12px;
                color: $text-title;
            }
        }
    }
}

.auth-form-states {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.auth-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-down(sm) {
        width: 100vw;
        padding-left: 20px;
        padding-right: 20px;
    }

    .auth-form-lead {
        text-align: center;

        @include media-breakpoint-up(sm) {
            width: 370px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .auth-form {
        min-width: 280px;

        @include media-breakpoint-up(sm) {
            width: 370px;
            background-color: $header-dark;
            border-radius: 7px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .auth-form-header {
            height: 80px;
            padding-top: 30px;
            font-family: $font-family-base;
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $text-title;
            border-bottom: 1px solid $outline-grey;
        }

        .auth-form-body {
            @include media-breakpoint-up(sm) {
                padding: 35px;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 35px;
                padding-bottom: 35px;
            }

            .auth-form-label {
                color: $text-title;
                font-size: 14px;
            }

            .auth-form-body-button {
                height: 55px;
                padding-left: 30px;
                padding-right: 30px;
                font-size: 14px;
                border-radius: 5px;
                // text-transform: uppercase;
            }

            .auth-form-multiple-button-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 30px;
            }
        }
    }
}

.auth-language-selector {
    position: absolute;
    top: 38px;
    right: 18px;
}

.auth-language-selector-mobile {
    position: absolute;
    top: 12px;
    right: 0;

    .language-selector {
        .custom-input-group {
            background-color: transparent;

            .custom-label-inline {
                margin-right: -20px;
                padding-top: 0;
            }

            .custom-select-wrapper {
                border: none;

                .custom-select__control {
                    min-width: 50px;

                    .custom-select__single-value {
                        display: none;
                    }
                }

                .custom-select__menu {
                    margin-left: -75px;
                }
            }
        }
    }
}

.auth-links {
    display: flex;
    align-items: center;
    justify-content: center;
}
