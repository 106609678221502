@import '../_variables.scss';
@import '../_mixins.scss';

.custom-alert {
    user-select: none;
}

.bp3-alert-body {
    color: $text-black;
    font-size: 14px;

    .bp3-alert-contents {
        user-select: text;
    }
}

.custom-alert-full {
    .bp3-alert-contents {
        width: 100%;
        text-align: center;
    }
}
