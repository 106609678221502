// Bootstrap
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins';

@mixin not-first-child($selector) {
    & #{$selector}:not(:first-child) {
        @content;
    }
}

@mixin not-last-child($selector) {
    & #{$selector}:not(:last-child) {
        @content;
    }
}
