@import './_variables.scss';
@import './_mixins.scss';

.mission-control-container {
    min-height: calc(100vh - 71px); // Fallback
    min-height: calc(var(--vh, 1vh) * 100 - 71px);
    padding-top: 10px;
    padding-bottom: 60px; // Allow space for help widget and offline banner
    background-color: $page-dark;

    .filter-mission-control-orgs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 20px 20px 20px;

        .custom-select-wrapper {
            width: 170px;

            .custom-select__menu {
                z-index: 3;
            }
        }
    }

    .mission-control-grid {
        padding: 20px;

        span > div {
            margin-left: auto;
            margin-right: auto;
        }

        .status {
            display: flex;
            flex-direction: row;
            width: 250px;
            height: 90px;
            flex: 1;
            border: 1px solid grey;
            min-height: 30px;
            border-radius: 5px;
            cursor: pointer;
            background-color: $card-background;

            .mission-control-title {
                width: 100%;
                position: absolute;
                z-index: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 10px;
                justify-content: space-between;

                .mission-control-org {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h3 {
                        margin-bottom: 0;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .overview-tag {
                        flex-shrink: 0;
                        margin-left: 10px;
                        background-color: grey;
                    }
                }
            }

            .mission-control-logo {
                width: 70%;
                margin-left: 30%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: left;
                    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                }
            }
        }
    }
}
