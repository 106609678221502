@import '../_variables.scss';
@import '../_mixins.scss';

.custom-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.custom-container-fluid {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;

    &.has-sidebar {
        display: flex;
        flex-direction: row;

        & > :last-child {
            // Offset the content container
            @include media-breakpoint-up(md) {
                width: calc(100% - 50px);
            }
        }
    }
}

.grey-container {
    background-color: $page-grey;
}
