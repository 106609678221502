@import '../_variables.scss';
@import '../_mixins.scss';

.custom-dropdown-toggle {
    display: inline-flex;
    align-items: center;
    height: 30px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-transform: none;

    h6 {
        margin-bottom: 0;
    }

    &:focus,
    &:active,
    &:hover,
    &:active:focus {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }

    .custom-dropdown-chevron {
        display: flex;
        align-items: center;
        height: 16px;
        width: 16px;
        margin-left: 20px;

        img {
            height: 9px;
            transition: transform 0.3s ease;
        }
    }
}

.custom-dropdown-menu {
    z-index: 1030;

    button {
        text-transform: none;
    }
}
