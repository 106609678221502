.help-label {
    display: inline-flex !important;
    flex-wrap: nowrap;

    .bp3-popover-wrapper {
        margin-top: 0 !important;
    }

    .help-label-icon {
        height: 15px;
        margin-left: 6px;
        margin-right: 6px;
    }
}
