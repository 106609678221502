// Fonts
@font-face {
    font-family: 'Inter-Black';
    src: url('../fonts/Inter/Inter-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url('../fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Light';
    src: url('../fonts/Inter/Inter-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Thin';
    src: url('../fonts/Inter/Inter-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

$font-family-sans-serif: 'Inter-Regular', sans-serif;
$font-family-semibold: 'Inter-SemiBold', sans-serif;
$font-family-light: 'Inter-Light', sans-serif;
$font-family-black: 'Inter-Black', sans-serif;
$font-family-heavy: 'Inter-ExtraBold', sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-family-display: 'Poppins-Medium', sans-serif;

// Colors
$theme-colors: (
    'primary': #6340f1,
    'secondary': #6c6d9b,
    'info': #a4a0fd,
    'success': #78c803,
    'warning': #ffd051,
    'error': #f6973b,
    'danger': #fe2260,
);
$brand-primary: #6340f1;
$brand-secondary: #6c6d9b;
$brand-tertiary: #6c6d9b;
$brand-quaternary: #33307f;
$brand-accent: #ffde9c;
$brand-info: #a4a0fd;
$brand-success: #78c803;
$brand-warning: #ffd051;
$brand-error: #f6973b;
$brand-danger: #fe2260;

$page-dark: #252525;
$page-grey: #4e4e4e;
$header-dark: #141518;
$filter-header: #ffffff;
$tooltip-background: #ffffff;
$dialog-background: #ffffff;
$card-background: #181a1e;
$card-header-background: #24272c;
$error-banner: #fe2260;

$table-group-background: #000000;
$table-hover: #24272c;
$table-selected: #24272c;
$table-error: #8b1616;
$table-error-dark: #4b0202;
$table-subheader-background: #4e4e4e;
$table-stopped: darken(#711a33, 10%);
$table-stopped-selected: darken(#881535, 10%);
$table-background-odd: #323232;

$scrollbar-thumb: #ffffff;

$text-nav: #888888;
$text-nav-active: #ffffff;
$text-title: #ffffff;
$text-title-alt: #888888;
$text-body-alt: #888888;
$text-button: #ffffff;
$text-data: #ffffff;
$text-input: #ffffff;
$text-input-alt: #888888;
$text-input-disabled: #888888;
$text-primary: #888888;
$text-black: #000000;
$text-hover: #ffffff;
$text-active: #ffffff;
$text-badge: #4e4e4e;
$text-badge-alt: #ffffff;
$text-error-banner: #ffffff;
$text-select: #ffffff;
$text-select-alt: #141518;
$text-select-active: #6340f1;
$text-dropzone: #878787;
$text-console: #ffffff;

$input-background: #1a1b1f;
$input-background-alt: #f6f8f8;
$input-background-ghost: #141518;
$input-border: #4e4e4e;
$input-border-alt: #ced4da;
$badge-background: #ffffff;
$badge-background-alt: #4e4e4e;
$badge-background-dark: #000000;
$nav-dropdown-background: #e6e6e6;
$select-hover: #e6e6e6;
$outline-grey: #4e4e4e;
$outline-light-grey: #888888;
$outline-light: #ced4da;
$outline-white: #ffffff;
$outline-black: #000000;
$outline-primary: #6340f1;

$button-primary: #6340f1;
$button-primary-hover: #33307f;
$button-primary-disabled: #252525;
$button-primary-text: #ffffff;
$button-primary-text-alt: #888888;
$button-secondary: #4e4e4e;
$button-secondary-hover: #4d4d4d;
$button-secondary-text: #ffffff;
$button-secondary-border: #6340f1;
$button-dark: #000000;
$button-dark-alt: #4e4e4e;
$button-dark-hover: #302f2f;
$button-dark-alt-hover: #4e4e4e;
$button-light: #ffffff;
$button-light-hover: #cccccc;
$button-checkbox-disabled: #6c757d;

$overlay-background: rgba(20, 21, 24, 0.5);
$overlay-background-light: rgba(177, 179, 183, 0.5);
$overlay-info-background: #ffffff;
$overlay-text: #141518;
$overlay-background-blur: blur(1px);

$cutout-box-shadow: -6px -6px 8px #101113, 0 0;

$custom-cyan: #17a2b8;
$custom-orange: #f6973b;
$custom-yellow: #ffd051;
$custom-blue: #6340f1;
$custom-purple: #6c6d9b;

$charcoal-grey: #2c3135;
$greyish: #aaaaaa;
$pale-grey: #f6f8f8;
$medium-grey: #e6e6e6;
$warm-grey: #9f9f9f;
$missing-grey: #484848;

$icon-light: #fafafa;

// Default variables
$reports-bottom-controls: false;
$tile-view-bottom-controls: false;
