@import '../_variables.scss';
@import '../_mixins.scss';

.datepicker-popover {
    padding-left: 12px;
}

.custom-datepicker-input {
    flex-wrap: wrap;

    .datepicker-tags {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 490px;

        .datepicker-tag {
            display: inline-flex;
            align-items: center;
            width: calc(50% - 20px);
            min-width: 205px !important;
            height: 38px;
            color: $text-input;
            border-radius: 5px !important;
            cursor: pointer;

            &:focus {
                outline: none !important;
            }

            .bp3-icon {
                margin-right: 4px;
            }
        }

        .input-valid {
            background-color: $button-secondary;
            border: 1px solid $button-secondary-border;
        }

        .input-invalid {
            background-color: $input-background !important;
            border: 1px solid $input-border !important;
        }

        .input-disabled {
            background-color: $input-background !important;
            border: 1px solid $input-border !important;
            color: $text-input-disabled;
            cursor: default;
        }

        .input-clearable {
            min-width: 230px !important;
        }

        .datepicker-arrow {
            margin-left: 5px;
            margin-right: 5px;
            color: $text-input;
        }
    }

    .datepicker-tags-inverted {
        .datepicker-tag {
            color: $text-input-alt;
            background-color: $input-background-alt;
        }

        .input-valid {
            color: $text-input;
        }

        .input-invalid {
            background-color: $input-background-alt !important;
        }

        .input-disabled {
            background-color: $input-background-alt !important;
            color: $text-input-disabled;
        }

        .datepicker-arrow {
            color: $text-input-alt;
        }
    }
}

@include media-breakpoint-down(sm) {
    .bp3-daterangepicker-shortcuts {
        width: 100%;
    }

    .custom-datepicker-input {
        & > .bp3-divider {
            width: 100%;
        }
    }
}

.datepicker-calendar {
    [class*='DayPicker-'] {
        &:focus {
            outline: none !important;
        }
    }

    .bp3-daterangepicker-timepickers {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .bp3-timepicker {
            margin-left: 10px;
            margin-right: 10px;

            .bp3-timepicker-arrow-row {
                display: flex;
            }
        }
    }

    .close-datepicker-button {
        width: calc(100% - 10px);
        margin: 5px;
    }
}

.datepicker-disabled-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 500px;
    height: 360px;
    margin-top: 10px;
    background-color: $overlay-background-light;
    border-radius: 3px;
    cursor: not-allowed;
    z-index: 1;
}

.custom-datepicker-input > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 440px;
}

.disable-start-time,
.disable-end-time {
    .bp3-daterangepicker-timepickers {
        width: 220px;
    }
}

.disable-start-time {
    .bp3-popover-arrow {
        left: 400px;
    }

    .bp3-timepicker:first-child {
        display: none;
    }
}

.disable-end-time {
    .bp3-popover-arrow {
        left: 110px;
    }

    .bp3-timepicker:last-child {
        display: none;
    }
}

.bp3-datepicker-caption select + .bp3-icon {
    right: 2px !important;
}

.bp3-datepicker {
    .DayPicker-Weekday,
    .DayPicker-Day {
        color: $text-black;

        &.DayPicker-Day--selected {
            background-color: $brand-primary !important;
        }
    }
}
