@import './_variables.scss';
@import './_mixins.scss';

.power-management {
    display: flex;
    flex-direction: column;
    height: 100%;

    .power-grid {
        height: 100%;
        overflow-y: auto;
        padding: 2rem;
    }
}

.grid-row {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .grid-line {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -0.5rem;
        margin-bottom: -0.5rem;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .grid-line-inner {
            height: 5rem;
            width: 2px;
            background-color: #2563eb;
        }

        .grid-line-edge {
            height: 2px;
            width: 5px;
            background-color: #2563eb;
        }
    }
}

.grid-node {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0.5rem;
    padding: 1rem;
    background-color: $charcoal-grey;
    border-radius: 0.375rem;

    .node-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.node-icon-header {
    display: flex;
    align-items: center;

    h3 {
        margin-bottom: 0;
    }

    .bp3-icon {
        margin-right: 0.75rem;
    }

    .status-indicator {
        margin-right: 0.75rem;
    }

    .node-header-badge {
        display: flex;
        align-items: center;
    }
}

.node-row {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.outputs {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.avior-header {
    display: flex;
    justify-content: space-between;
}

.avior-popover {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.output-control {
    width: 100%;
}

.status-indicator {
    display: flex;
    height: 1.75rem;
    width: 1.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    .status-indicator-inner {
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 100%;
    }
}

.bg-green-100 {
    background-color: #e4f4cd;
}

.bg-green-400 {
    background-color: #78c803;
}

.bg-red-100 {
    background-color: #ffd3df;
}

.bg-red-400 {
    background-color: #fe2260;
}

.bg-gray-100 {
    background-color: #f3f4f6;
}

.bg-gray-400 {
    background-color: #9ca3af;
}

.text-green-500 {
    color: $brand-success !important;
}

.text-yellow-500 {
    color: $brand-warning !important;
}

.text-orange-500 {
    color: $brand-error !important;
}

.text-red-500 {
    color: $brand-danger !important;
}

.text-blue-500 {
    color: $brand-primary !important;
}

.text-gray-400 {
    color: #9ca3af !important;
}
