@import './_variables.scss';
@import './_mixins.scss';

.unsupported-browser {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-width: 320px;
    height: 100vh; // Fallback
    height: calc(var(--vh, 1vh) * 100);
    padding: 40px 20px 20px 20px;
    text-align: center;

    .browser-links {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;

        .browser-link {
            display: flex;
            flex-direction: column;
            width: 128px;
            margin: 20px;
        }
    }
}
