@import './_variables.scss';
@import './_mixins.scss';

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 200px;

    img {
        height: 40px;
    }
}
