@import '../_variables.scss';
@import '../_mixins.scss';

// Remove border on focus
.react-toggle-thumb {
    box-shadow: none !important;
    border: none !important;
}

.custom-toggle .react-toggle-track {
    width: 40px;
    background-color: $button-dark-alt;
}

.custom-toggle.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $button-dark-alt-hover;
}

.custom-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $button-primary-hover;
}

.custom-toggle.react-toggle--checked {
    .react-toggle-track {
        width: 40px;
        background-color: $button-primary;
    }

    .react-toggle-thumb {
        left: 17px;
    }
}

.custom-toggle-small {
    .react-toggle-track {
        width: 30px;
        height: 17px;
    }

    .react-toggle-thumb {
        width: 14px;
        height: 14px;
        margin-top: 1px;
    }
}

.custom-toggle-small.react-toggle--checked {
    .react-toggle-track {
        width: 30px;
    }

    .react-toggle-thumb {
        left: 14px;
    }
}
