@import '../_variables.scss';
@import '../_mixins.scss';

.select-hidden {
    display: none !important;
}

.custom-select-wrapper {
    width: 100%;
    padding-right: 10px;

    .custom-select__menu {
        width: auto;
        min-width: 140px;
        max-width: min(calc(100vw - 40px), 600px);
        color: $header-dark;
        font-size: 14px;
        border-radius: 7px;
        box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.5);
        z-index: 2;

        .custom-select__option {
            display: inline-flex;
            align-items: center;
            width: 100%;
            padding: 6px 5px 6px 5px;
            white-space: nowrap;

            &:hover:not(.custom-select__option--is-disabled),
            &.isFocused {
                color: inherit;
                background-color: $select-hover !important;
                cursor: pointer;
            }

            &.isSelected {
                color: $text-select-active;
                background-color: inherit;
            }

            &.isSingle .custom-checkbox {
                border: none;
                background-color: transparent;
            }

            .custom-checkbox {
                flex-shrink: 0;
                margin-right: 6px;
                cursor: inherit;
            }

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.custom-select-ghost {
    .custom-select__control {
        background-color: $input-background-ghost !important;
        border-color: transparent !important;
    }

    .custom-select__indicators {
        visibility: hidden;
    }
}

.custom-select__control {
    display: flex;
    width: 100%;
    min-width: 140px;
    height: 35px;
    background-color: $input-background !important;
    color: $text-select !important;
    font-size: 14px !important;
    caret-color: $text-input;
    border: 1px solid $outline-grey !important;
    border-radius: 5px !important;
    box-shadow: none !important;

    &:hover {
        cursor: pointer;
    }

    .custom-select__value-container {
        display: inline-flex;
        padding: 6px 8px;

        .custom-select__placeholder {
            color: inherit;
        }

        .custom-select__input {
            color: $text-select;

            span {
                display: none !important;
            }
        }

        .custom-select__single-value {
            color: $text-select;
            font-size: 14px;
        }

        .custom-select__multi-value {
            max-width: calc(100% - 4px);
            font-size: 14px;
            color: $text-select;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .custom-value-container {
            position: absolute;
        }
    }

    .custom-select-clear-indicator {
        height: 10px;
        transition: filter 150ms;

        &:hover:not(.custom-select__control--is-disabled) {
            filter: brightness(60%);
        }
    }

    .select-dropdown-indicator-container {
        display: flex;
        box-sizing: border-box;
        padding: 8px;

        .select-dropdown-chevron {
            height: 9px;
            transition: transform 0.3s ease;
        }
    }

    .custom-select__indicator-separator {
        background-color: $outline-grey;
    }
}

.custom-select-inverted {
    .custom-select__control {
        color: $text-select-alt !important;
        background-color: #ffffff !important;
        border-color: $input-border-alt !important;
        caret-color: $text-input-alt;

        .custom-select__input {
            color: $text-select-alt;
        }

        .custom-select__single-value {
            color: $text-select-alt;
        }

        .custom-select__multi-value {
            color: $text-select-alt;
        }

        .select-dropdown-indicator-container {
            filter: brightness(70%);
        }

        .custom-select-clear-indicator {
            filter: brightness(70%);

            &:hover:not(.custom-select__control--is-disabled) {
                filter: brightness(30%);
            }
        }

        .custom-select__indicator-separator {
            background-color: $input-border-alt;
        }
    }
}

.custom-select__control--is-disabled {
    border: none !important;

    .select-dropdown-indicator-container,
    .custom-select__indicator-separator {
        display: none;
    }

    .custom-select__input {
        color: $text-select;
    }

    .custom-select__single-value {
        color: $text-select;
    }

    .custom-select__multi-value {
        color: $text-select;
    }
}

.custom-select__group {
    .select-group-select-all {
        display: flex;
        align-content: center;
        height: 20px;

        .custom-select__group-heading {
            margin-top: 5px;
        }

        .custom-button-link {
            height: auto;
            margin: 0 5px 0 auto;

            span {
                font-size: 12px;
            }
        }
    }
}
