@import '../_variables.scss';
@import '../_mixins.scss';

.custom-badge {
    min-width: 16px;
    height: 16px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -8px;
    margin-left: -8px;
    color: $text-badge;
    font-size: 11px;
    font-family: $font-family-heavy;
    text-align: center;
    border-radius: 32px;
    background-color: $badge-background;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
}

.custom-badge-alt {
    color: $text-badge-alt;
    background-color: $badge-background-alt;
}
