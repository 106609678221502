@import '../_variables.scss';
@import '../_mixins.scss';

// Base Virtualized MultiGrid as Table
// BVM
.bvm-table {
    color: #ffffff;
    font-size: 14px;

    &:focus {
        outline: none !important;
    }

    [class*='ReactVirtualized__'] {
        &:focus {
            outline: none !important;
        }
    }

    .ReactVirtualized__Grid__innerScrollContainer {
        user-select: text;
    }

    .ReactVirtualized__Table__headerRow {
        padding-bottom: 3px;
        border-bottom: 1px solid $outline-grey;
        user-select: none;

        .ReactVirtualized__Table__headerColumn {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .ReactVirtualized__Table__sortableHeaderColumn {
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            .sortable-header-icon {
                height: 6px;
                margin-left: 3px;
                margin-bottom: 3px;
            }
        }
    }

    .ReactVirtualized__Table__Grid .ReactVirtualized__Table__row {
        padding-right: 0 !important;
    }

    .ReactVirtualized__Table__rowColumn {
        overflow: hidden;
    }

    .oddRow {
        background-color: $table-background-odd;
    }
}

.error-log-table {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    // padding-top: 20px;

    .bvm-table {
        padding-bottom: 60px; // Allow space for help widget and offline banner
    }

    .data-cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.end-of-results-row {
    width: 100% !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    h4 {
        margin-bottom: 0;
    }
}
