@import '../_variables.scss';
@import '../_mixins.scss';

.nav-tabs-container {
    display: inline-flex;
    flex-shrink: 0;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .nav-tabs {
        flex-wrap: nowrap;
        flex-grow: 1;
        // max-height: 67px;
        margin-bottom: 10px;
        padding-top: 20px;
        padding-bottom: 16px;
        border-bottom: 1px solid $outline-grey;
        overflow-x: auto;
        overflow-y: hidden;

        @include not-last-child('.nav-item') {
            border-right: 1px solid $outline-grey;
        }

        .nav-item {
            max-height: 36px;
            background-color: transparent;
            border-radius: 0;
            color: $text-title-alt;
            white-space: nowrap;
            overflow-y: visible;
            z-index: 0; // Prevent rendering on top of shadows

            h3 {
                margin-bottom: 0;
            }

            .nav-link {
                background-color: transparent;
                border: none;
                border-radius: 0;
            }

            .nav-link.active {
                padding-bottom: 17px;
                color: $text-active !important;
                border-bottom: 4px solid $button-primary;
            }
        }
    }

    .nav-tabs-light {
        .nav-item {
            border-color: $outline-light !important;

            span {
                color: $text-black;
            }
        }
    }

    .custom-select-wrapper {
        max-width: 200px;
        margin-bottom: 10px;
    }
}

.nav-tabs-contextual-actions {
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
        align-self: center;
        margin-left: auto;
    }

    @include media-breakpoint-up(md) {
        min-width: 150px;
        margin-right: -20px;
        padding: 10px;
        border-top-left-radius: 5px;
        background: $page-dark;
        box-shadow: $cutout-box-shadow;
    }

    h5 {
        border-bottom: none !important;
    }

    .form-group {
        margin-right: 0;
        margin-bottom: 0;
    }

    .errors-table-collapse-all {
        margin-right: 8px;
        padding-left: 8px;
        padding-right: 8px;
        border-right: 1px solid $outline-grey;
        border-radius: 0;

        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }

    .custom-button-link-black span {
        border: none;
    }

    .errors-table-compact-view {
        align-items: center;
        height: 28px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }

        .custom-form-group-inline {
            display: inline-flex;
            align-items: center;
        }
    }
}
