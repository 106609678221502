@import '../_variables.scss';
@import '../_mixins.scss';

.banner-toaster {
    width: 100%;

    .bp3-toast {
        width: 100%;
        max-width: 100% !important;

        .bp3-button-group {
            // Disable dismiss button for app banners
            display: none;
        }
    }
}

.app-toaster {
    padding-bottom: calc(var(--freshworks-frame-offset, 1px) + 60px);
}

.bp3-toast {
    font-size: 14px;
}
