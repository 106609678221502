// CSS Transitions
.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 1s ease-out;
}

//

.fade-background-colour {
    transition: background-color 0.3s ease;
}

.rotate-center {
    animation: rotate-center 2s ease-in-out infinite both;
}

@keyframes rotate-center {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.glow-success {
    animation: glow-success 1s ease-out 300s both;
    // animation: glow-success 1s ease-out 5s both;
}

@keyframes glow-success {
    0% {
        box-shadow: 0 0 2px 2px $success;
    }
    100% {
        box-shadow: none;
    }
}

.glow-danger {
    animation: glow-danger 1s ease-out 300s both;
    // animation: glow-danger 1s ease-out 5s both;
}

@keyframes glow-danger {
    0% {
        box-shadow: 0 0 2px 2px $danger;
    }
    100% {
        box-shadow: none;
    }
}
