@import './_variables.scss';
@import './_mixins.scss';

.under-construction-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 71px); // Fallback
    min-height: calc(var(--vh, 1vh) * 100 - 71);
    overflow: hidden;
}

.under-construction-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
}

.under-construction-text {
    text-align: center;
    font-size: 24px;
    color: $text-title;
    margin-top: 100px;
    padding: 10px;
}
