@import '../_variables.scss';
@import '../_mixins.scss';

// Vanilla tables
table tbody {
    user-select: text;
}

// Base Virtualized Table
// BVT
.bvt-table {
    color: #ffffff;
    font-size: 14px;

    &:focus {
        outline: none !important;
    }

    [class*='ReactVirtualized__'] {
        &:focus {
            outline: none !important;
        }
    }

    .ReactVirtualized__Table__headerRow {
        user-select: none;
        padding-right: 15px !important; // Allow room for scrollbar

        .ReactVirtualized__Table__headerColumn {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .ReactVirtualized__Table__sortableHeaderColumn {
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            .sortable-header-icon {
                height: 6px;
                margin-left: 3px;
            }
        }
    }

    .ReactVirtualized__Table__Grid {
        user-select: text;

        &:focus {
            outline: none;
        }

        .ReactVirtualized__Table__row {
            padding-right: 15px !important; // Allow room for scrollbar
            border-bottom: 1px solid $outline-grey;
        }
    }
}

// NOTE: react-virtualized row can be rendered outside of table wrapper when sorting
.ReactVirtualized__Table__row {
    display: flex;
    align-items: center;
    color: $text-data;
    font-size: 14px;
    z-index: 3010;

    .drag-handle {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 5px;
        cursor: grab;
        touch-action: manipulation;
        transition: opacity 0.6s cubic-bezier(0, 0.52, 0, 1);

        &.show {
            // width: 24px
            opacity: 1;
        }

        &.hide {
            // width: 0px
            // margin-left: 5px
            // margin-right: -5px
            // visibility: hidden
            pointer-events: none;
            opacity: 0 !important;
        }

        img {
            height: 9px;
        }
    }
}

// Row states
.vtr-loading {
    border: none !important;
}

.vtr-selected {
    background-color: $table-group-background;
    box-shadow: 6px 0 $brand-primary inset;
}

.vtr-stopped {
    background-color: $table-stopped;

    &.vtr-selected,
    &:hover {
        background-color: $table-stopped-selected !important;
    }
}

.vtr-disabled {
    opacity: 0.5;
}

// Table actions
.table-action {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 30px;
}

.table-action-lg {
    width: 35px;
    height: 35px;
    padding: 0;
    border-radius: 35px;
}

// No results
.bvt-no-results {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

// Select
.row-select-disabled {
    width: 25px;
}
