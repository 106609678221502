@import '../_variables.scss';
@import '../_mixins.scss';

.custom-search {
    display: flex;
    align-items: center;

    .form-control {
        width: 100% !important;
        margin-right: 10px;
        padding-left: 40px;
        padding-right: 10px;
        background-color: $header-dark;
        border-radius: 36.5px;
        border: 1px solid $outline-grey;
        font-family: $font-family-base;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $text-input;
    }

    .custom-search-icon {
        position: absolute;
        height: 16px;
        margin-left: 16px;

        // Allow click through to input field below
        pointer-events: none;
    }
}

.custom-search-large {
    .form-control {
        height: 55px;
        padding-left: 60px;
    }

    .custom-search-icon {
        height: 22px;
        margin-left: 22px;
    }
}
