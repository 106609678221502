@import './_variables.scss';
@import './_mixins.scss';

.language-selector {
    .custom-input-group {
        height: 44px;

        .globe-icon {
            height: 16px;
            padding-left: 4px;
            padding-right: 4px;
        }

        .custom-select-wrapper {
            padding-right: 0;
            border-left: 1px solid $outline-grey;

            .custom-select__control {
                min-width: 120px;
                background-color: transparent !important;
                border: none !important;
                padding-left: 2px;

                .custom-select__indicator-separator {
                    display: none;
                }
            }

            .custom-select__menu {
                min-width: 120px;
            }
        }
    }
}
