@import '../_variables.scss';
@import '../_mixins.scss';

form {
    @include media-breakpoint-down(sm) {
        // .custom-form-group:not(:last-child),
        // .custom-input-group:not(:last-child) {
        // }

        margin-bottom: 20px;
    }
}

.form-control {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    border-color: $input-border !important;
}

.form-control-alt {
    border-color: $input-border-alt !important;
}

// Remove form element focus outline and glow
form div,
.form-group div,
.form-control {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.form-inline .form-group {
    width: 100%;
}

.form-text {
    font-size: 12px;
}

.custom-input-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-grow: 1;
    height: 55px;
    max-height: 55px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 7px;
    background-color: $header-dark;
}

.custom-form-group-inline,
.custom-input-group-inline {
    width: auto !important;
    flex-grow: 0;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.custom-from-group-inverted,
.custom-input-group-inverted {
    background-color: $page-dark;
}

.custom-from-group-alt,
.custom-input-group-alt {
    background-color: $input-background-alt;
}

.checkbox-form-group {
    display: flex;
    align-items: center;
}

.custom-label {
    white-space: nowrap;
    margin-bottom: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }
}

.custom-label-inline {
    margin-right: 10px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 2px;
    }
}

.custom-input {
    .form-control,
    .form-control:not(:first-child),
    .form-control:not(:last-child) {
        height: 35px;
        background-color: $input-background;
        border-radius: 5px;
        border: 1px solid $outline-grey;
        color: $text-input;
        font-size: 14px;
    }
}

.custom-input-inverted {
    .form-control,
    .form-control:not(:first-child),
    .form-control:not(:last-child) {
        background-color: $page-dark;
    }
}

.custom-input-inline {
    .form-control,
    .form-control:not(:first-child),
    .form-control:not(:last-child) {
        margin-right: 10px;
        padding-top: 7px;
    }
}

.custom-input-prepend-icon {
    .form-control {
        padding-left: 30px;
    }
}

.custom-input-icon-left {
    position: absolute;
    z-index: 1000;
    margin-top: 12px;
    margin-left: 10px;
    height: 11px;

    // Allow click through to input field below
    pointer-events: none;
}

.custom-text-input {
    min-width: 50px;
    height: 35px;
    margin-right: 10px;
    padding-top: 7px;
    padding-left: 10px;
    background-color: $input-background;
    color: $text-input;
    border-radius: 5px !important;
    border: 1px solid $outline-grey;
    font-size: 14px;

    &:not(:disabled):not(:focus) {
        color: $text-input;
    }

    &:disabled {
        background-color: transparent;
    }
}

.custom-input-ghost {
    background-color: $input-background-ghost !important;
    border: none;
}

.datepicker-inline {
    .custom-input-group {
        flex-grow: unset;

        @include media-breakpoint-up(sm) {
            flex-wrap: nowrap !important;
            margin-right: 10px;

            .custom-label {
                margin-right: 10px;
            }
        }

        @include media-breakpoint-down(xs) {
            .custom-label {
                margin-bottom: 15px;
            }
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            flex-wrap: wrap;
            height: unset;
            max-height: unset;

            .bp3-popover-wrapper {
                width: 100%;
                flex-grow: 1;

                .datepicker-tags {
                    width: 100%;

                    .datepicker-tag {
                        min-width: 120px !important;
                    }
                }
            }
        }

        .custom-select-wrapper {
            min-width: 180px;
        }
    }
}
