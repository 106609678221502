@import './_variables.scss';
@import './_mixins.scss';

.admin-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    height: calc(100vh - 71px); // Fallback
    height: calc(var(--vh, 1vh) * 100 - 71px);
    background-color: $page-dark;
    padding-left: 20px;
    padding-right: 20px;

    .tab-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;

        .admin-table-banner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            @include media-breakpoint-up(md) {
                flex-wrap: nowrap;

                .custom-search-icon,
                .custom-button-large {
                    img {
                        margin-left: 6px;
                    }
                }
            }

            .admin-search {
                @include media-breakpoint-down(sm) {
                    width: 100% !important;
                }
            }

            .show-deleted {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 0 !important;
                    margin-left: 5px;
                }

                @include media-breakpoint-up(md) {
                    margin-right: auto;
                }
            }

            .custom-form-group {
                margin-bottom: 20px;
            }

            button {
                white-space: nowrap;
            }
        }

        .admin-table-banner-actions {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .batch-action-form-group {
                display: inline-flex;
                align-items: center;
                height: 55px;
                padding: 0 10px;
                background-color: $header-dark;

                .custom-label {
                    margin-right: 10px;
                }
            }

            .version-select {
                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                    width: 100% !important;
                    height: unset;

                    .custom-label,
                    .custom-button-small {
                        margin: 10px 0;
                    }
                }
            }

            .admin-action-buttons {
                flex-shrink: 0;
                // margin-left: auto !important;
                margin-bottom: 20px;
            }

            .clear-selected-button {
                margin-top: 0;

                img {
                    height: 12px;
                }
            }
        }

        .admin-table {
            flex-grow: 1;
            width: 100%;
            height: 100%;

            .bvm-table {
                padding-bottom: 60px; // Allow space for help widget and offline banner
            }

            .access-cell {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .console-icon-wrapper {
                    margin-left: 10px;
                }
            }

            .actions-cell {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .table-action-wrapper {
                    margin-right: 10px;
                }

                .fill-left {
                    margin-left: 40px;
                }
            }

            .deleted-cell {
                color: $text-primary;

                &:before {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    border-bottom: 1px solid $outline-light-grey;
                    width: 100%;
                }
            }

            .disabled-cell {
                color: $text-primary;
            }
        }
    }
}

.admin-panel-stack {
    border-radius: 6px;
    height: calc(100vh - 100px); // Fallback
    height: calc(var(--vh, 1vh) * 100 - 100px);
    max-height: 760px;
}

.admin-form {
    width: 100%;
    color: $text-black;

    .form-control {
        border-color: $input-border-alt !important;
    }

    .custom-select-wrapper {
        padding-right: 0;
    }

    .role-option {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid $outline-light;
    }

    .assign-permissions {
        height: 198px;
        overflow-y: auto;
    }
}

.admin-saving {
    h2 {
        padding: 25px;
        border-radius: 5px;
        color: $overlay-text;
        background-color: $overlay-info-background;
    }
}

.copy-ids-dialog {
    display: flex;
    align-items: flex-start !important;
    padding: 20px !important;

    .copy-row {
        width: 100%;
        display: inline-flex;
        align-items: center;
        margin-bottom: 5px;
        padding-bottom: 5px;

        &:not(:last-child) {
            border-bottom: 1px solid $outline-light;
        }

        .bp3-popover-wrapper {
            margin-right: 10px;
        }

        h3 {
            color: $text-black;
            margin-bottom: 0;
        }
    }
}

.id-copied-success {
    display: inline-flex;
    align-items: center;
}
