@import '../_variables.scss';
@import '../_mixins.scss';

.collapsible-title {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    color: $text-title;
    cursor: pointer;

    h1,
    h2,
    h3,
    h4,
    h4,
    h6 {
        margin-bottom: 0;
    }

    span {
        transition: 0.3s ease;
    }

    .bp3-icon {
        margin-left: 20px;
    }
}
