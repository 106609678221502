@import '../_variables.scss';
@import '../_mixins.scss';

.custom-slider.rc-slider {
    margin-top: -8px;
    margin-left: 20px;
    margin-right: 20px;

    .rc-slider-rail,
    .rc-slider-track {
        height: 9px;
        background-color: $button-primary;
        border-radius: 12.5px;
    }

    .rc-slider-handle {
        height: 20px;
        width: 20px;
        border: none;

        // Remove border on focus
        &:focus {
            box-shadow: none;
        }
    }

    .rc-slider-step,
    .rc-slider-mark {
        display: none;
    }
}
