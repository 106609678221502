@import '../_variables.scss';
@import '../_mixins.scss';

.btn {
    color: $text-title !important;
}

.btn,
button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-display;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-title;
    // text-transform: uppercase;
    transition: background-color 0.3s ease;
    white-space: nowrap;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }

    // Remove button focus outline and glow
    &:focus,
    &:active,
    &:active:focus,
    &.active:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-primary {
    &.disabled,
    &:disabled {
        color: $button-primary-text-alt;
        background-color: $button-primary-disabled;
        border-color: $outline-grey;
    }
}

.custom-button-large {
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
}

.custom-button-small {
    height: 37px;
    padding-left: 15px;
    padding-right: 15px;
}

.custom-button-icon-left {
    img {
        margin-right: 9px;
    }
}

.custom-button-icon-right {
    img {
        margin-left: 9px;
    }
}

.custom-button-upload {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-transform: none;
    background-color: $header-dark;
    border-width: 2px;
    border-radius: 5px;
    border-color: $outline-grey;
    border-style: dashed;
    outline: none;

    img {
        margin-right: 9px;
    }

    h4 {
        width: 100%;
    }
}

.btn-link {
    span {
        white-space: nowrap;
    }

    &:focus,
    &.focus {
        text-decoration: none;
    }

    &:hover {
        color: $button-primary-text;
        text-decoration: none;
    }
}

.custom-button-secondary {
    background-color: $button-secondary;
    border: 1px solid $button-secondary-border;
}

.custom-button-link {
    height: 55px;
    margin-top: 3px;

    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: none;
        border-bottom: 1px solid $outline-white;
    }
}

.custom-button-link-black {
    span {
        border-bottom: 1px solid $header-dark;
        color: $header-dark;
    }
}

.custom-button-link-sm {
    height: 25px;
    padding: 0;
}

.custom-button-edit {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 30px;
}

.custom-checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    margin-top: 2px;
    padding: 0;
    color: $button-primary;
    background-color: $button-light;
    border-color: $outline-black !important;

    @include media-breakpoint-down(md) {
        &:hover:not([class*='disabled']),
        &:focus:not([class*='disabled']) {
            background-color: $button-light;
        }
    }

    @include media-breakpoint-up(md) {
        &:hover:not([class*='disabled']),
        &:focus:not([class*='disabled']) {
            background-color: $button-light-hover;
        }
    }

    // Checkbox in disabled state after being selected
    &.disabled-selected {
        background-color: $button-checkbox-disabled;
    }

    .custom-checkbox-icon {
        margin: 0;
        height: 9.8px;
    }
}

.back-to-top-button {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 19;

    img {
        &:hover {
            filter: brightness(110%);
            cursor: pointer;
        }
    }
}

.button-requires-confirmation {
    justify-content: flex-start;
}
